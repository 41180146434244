"use client";

import { Hourglass } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="loader-container">
      <h4 className="mb-4" style={{color: 'white'}}>Whipping Up Your Delicious Dishes</h4>
      <Hourglass
        visible={true}
        height="80"
        width="80"
        ariaLabel="hourglass-loading"
        wrapperStyle={{}}
        wrapperClass="mt-2"
        colors={["#1eb8e8", "#7192f8"]}
      />
    </div>
  );
};
