import Link from "next/link";
import { Dispatch, useContext, useEffect, useState } from "react";
import { MetadataContext } from "../../context/metadata.context";
import {
  ActionType,
  OrderContext,
  OrderStateModel,
} from "@/context/order.context";
import LoginForm from "../loginForm";
import { Avatar } from "primereact/avatar";
import Dropdown from "react-bootstrap/Dropdown";
import { LocalStorageService } from "@/services/localStorage.service";
import ToastService from "@/services/toast.service";
import { useRouter } from "next/navigation";
import Image from "next/image";

export default function Header1({
  headerCls,
  scroll,
  isToggled,
  handleToggle,
}) {
  const router = useRouter();
  const { state } = useContext(MetadataContext);
  const [activeMenu, setActiveMenu] = useState("");
  const [addedItemsCount, setAddedItemsCount] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { state: orderState } = useContext<{
    state: OrderStateModel;
    dispatch: Dispatch<ActionType>;
  }>(OrderContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const href = window.location.href;
    if (window.location.pathname === "/") {
      setActiveMenu("home");
    } else if (href.includes("about")) {
      setActiveMenu("about");
    } else if (href.includes("order-online")) {
      setActiveMenu("orderOnline");
    } else if (href.includes("menu-card")) {
      setActiveMenu("menuCard");
    } else if (href.includes("faqs")) {
      setActiveMenu("faqs");
    } else if (href.includes("contact-us")) {
      setActiveMenu("contactUs");
    }
    checkForIsLoggedIn();
  }, []);

  useEffect(() => {
    let addedCount = 0;
    orderState.order.orders[0].items.forEach((item) => {
      addedCount += item.quantity;
    });
    setAddedItemsCount(addedCount);
  }, [orderState.order]);

  const checkForIsLoggedIn = () => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  return (
    <>
      <header
        id="header-1"
        className={`header navik-header header-shadow center-menu-1 ${
          headerCls ? headerCls : ""
        } ${scroll ? "sticky" : ""}`}
      >
        <div className="container">
          <div className="navik-header-container">
            <div className="callusbtn">
              <Link href="/tel:123456789">
                <i className="fas fa-phone" />
              </Link>
            </div>

            <div
              className="logo"
              data-mobile-logo="images/logo-01.png"
              data-sticky-logo="images/logo-01.png"
              onClick={() => setActiveMenu("home")}
              role="button"
            >
              {state.location?.logo && (
                <Image
                  height={0}
                  width={0}
                  src={state.location?.logo}
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                  alt={`Logo`}
                  priority
                  onClick={() => router.push("/")}
                />
              )}
            </div>

            <div
              style={{
                position: "fixed",
                top: "22px",
                right: "66px",
              }}
              className="basket-ico ico-30"
            >
              {isLoggedIn ? (
                <Dropdown className="user-profile-dropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <Avatar
                      icon="pi pi-user"
                      style={{
                        cursor: "pointer",
                      }}
                      shape="circle"
                      className="mr-3"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item>Profile</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        router.push("/orders");
                      }}
                    >
                      My Orders
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        LocalStorageService.logout();
                        ToastService.showSuccess("Logout successful");
                        checkForIsLoggedIn();
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
            </div>

            <div
              style={{
                position: "fixed",
                top: "27px",
                right: "60px",
              }}
              className="basket-ico ico-30"
            >
              <Link href="/cart" prefetch={false}>
                <span className="ico-holder">
                  <span
                    style={{ color: "black" }}
                    className="flaticon-shopping-bag"
                  />{" "}
                  <em style={{ marginTop: "-10px" }} className="roundpoint">
                    {addedItemsCount}
                  </em>
                </span>
              </Link>
            </div>

            <div
              className={`burger-menu ${isToggled ? "menu-open" : ""}`}
              onClick={handleToggle}
            >
              <div className="line-menu line-half first-line" />
              <div className="line-menu" />
              <div className="line-menu line-half last-line" />
            </div>

            <nav className="navik-menu menu-caret navik-yellow">
              <ul className={`top-list ${isToggled ? "d-block" : "d-none"}`}>
                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={activeMenu === "home" ? "menu-item-active" : ""}
                    href="/"
                  >
                    Home
                  </Link>
                </li>

                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={activeMenu === "about" ? "menu-item-active" : ""}
                    href="/about"
                  >
                    About
                  </Link>
                </li>

                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={
                      activeMenu === "orderOnline" ? "menu-item-active" : ""
                    }
                    href="/order-online"
                  >
                    Order Online
                  </Link>
                </li>
              </ul>
              <div className="logoCenter" style={{ width: "151.453px" }}>
                <div
                  className="logo"
                  data-mobile-logo={state.location?.logo}
                  data-sticky-logo={state.location?.logo}
                >
                  <Link
                    prefetch={false}
                    href="/"
                    className="d-flex justify-content-center"
                  >
                    {state.location?.logo && (
                      <Image
                        height={0}
                        width={0}
                        src={state.location?.logo}
                        style={{
                          width: "auto",
                          height: "auto",
                        }}
                        alt={`Logo`}
                        priority
                      />
                    )}
                  </Link>
                </div>
              </div>

              <ul className={isToggled ? "d-block" : "d-none"}>
                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={
                      activeMenu === "menuCard" ? "menu-item-active" : ""
                    }
                    href="/menu-card"
                  >
                    Menu Card
                  </Link>
                </li>
                {/* <li className="dropdown_menu">
                  <Link
                    className={activeMenu === "faqs" ? "menu-item-active" : ""}
                    href="/faqs"
                  >
                    FAQs
                  </Link>
                </li> */}
                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={
                      activeMenu === "contactUs" ? "menu-item-active" : ""
                    }
                    href="/contact-us"
                  >
                    Contact Us
                  </Link>
                </li>
                {isLoggedIn ? (
                  <></>
                ) : (
                  <li className="dropdown_menu">
                    <Link href="#" onClick={() => setShowLoginModal(true)}>
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>

        {showLoginModal ? (
          <LoginForm
            onClose={() => {
              setShowLoginModal(false);
              checkForIsLoggedIn();
            }}
          />
        ) : (
          <></>
        )}
      </header>
    </>
  );
}
