import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import { MetadataContext } from "../../context/metadata.context";

export default function Header2({
  headerCls,
  scroll,
  isToggled,
  handleToggle,
}) {
  const { state } = useContext(MetadataContext);
  const [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    const href = window.location.href;
    if (window.location.pathname === "/") {
      setActiveMenu("home");
    } else if (href.includes("about")) {
      setActiveMenu("about");
    } else if (href.includes("order-online")) {
      setActiveMenu("orderOnline");
    } else if (href.includes("menu-card")) {
      setActiveMenu("menuCard");
    } else if (href.includes("faqs")) {
      setActiveMenu("faqs");
    } else if (href.includes("contact-us")) {
      setActiveMenu("contactUs");
    }
  }, []);

  return (
    <>
      <header
        id="header-3"
        className={`header navik-header header-shadow viewport-lg ${
          headerCls ? headerCls : ""
        } ${scroll ? "sticky" : ""}`}
      >
        <div className="container">
          <div className="navik-header-container">
            <div className="callusbtn">
              <Link href="/tel:123456789">
                <i className="fas fa-phone" />
              </Link>
            </div>

            <div
              className="logo"
              data-mobile-logo="images/logo-02.png"
              data-sticky-logo="images/logo-02.png"
            >
              <Link href="#hero-6">
                <img src="/images/logo-02.png" alt="header-logo" />
              </Link>
            </div>

            <div
              className={`burger-menu ${isToggled ? "menu-open" : ""}`}
              onClick={handleToggle}
            >
              <div className="line-menu line-half first-line" />
              <div className="line-menu" />
              <div className="line-menu line-half last-line" />
            </div>

            <nav className="navik-menu menu-caret navik-yellow">
              <ul className={`top-list ${isToggled ? "d-block" : "d-none"}`}>
                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={activeMenu === "home" ? "menu-item-active" : ""}
                    href="/"
                  >
                    Home
                  </Link>
                </li>

                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={activeMenu === "about" ? "menu-item-active" : ""}
                    href="/about"
                  >
                    About
                  </Link>
                </li>

                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={
                      activeMenu === "orderOnline" ? "menu-item-active" : ""
                    }
                    href="/order-online"
                  >
                    Order Online
                  </Link>
                </li>

                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={
                      activeMenu === "menuCard" ? "menu-item-active" : ""
                    }
                    href="/menu-card"
                  >
                    Menu Card
                  </Link>
                </li>
                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={activeMenu === "faqs" ? "menu-item-active" : ""}
                    href="/faqs"
                  >
                    FAQs
                  </Link>
                </li>
                <li className="dropdown_menu">
                  <Link
                    prefetch={false}
                    className={
                      activeMenu === "contactUs" ? "menu-item-active" : ""
                    }
                    href="/contact-us"
                  >
                    Contact Us
                  </Link>
                </li>

                <li className="basket-ico ico-30">
                  <Link prefetch={false} href="/cart">
                    <span className="ico-holder">
                      <span className="flaticon-shopping-bag" />{" "}
                      <em className="roundpoint">2</em>
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
