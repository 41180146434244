import { useEffect } from "react";

const PageHead = ({ headTitle }) => {
  useEffect(() => {
    document.title = headTitle;
  }, [headTitle]);

  return (
    <></>
  );
};

export default PageHead;
