import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import fetchApi from "@/services/api-handler";
import ToastService from "@/services/toast.service";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { Dispatch, useContext, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { LocalStorageService } from "@/services/localStorage.service";
// import { DataDogService } from "@/services/dataDog.service";

export default function LoginForm({ onClose }: { onClose: Function }) {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailId, setEmailId] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);
  const [mode, setMode] = useState(0);
  const [loading, setLoading] = useState(false);
  // 0 - Phonenumber enter, 1 - Verify Otp, 2 - Register

  useEffect(() => {
    // setPhoneNumber('');
    setOtp("");
    setMode(0);
  }, []);

  const sendOtp = async () => {
    let noMaskPhoneNumber = phoneNumber.replace(/[\(\)\s-]/g, "");
    if (noMaskPhoneNumber?.length === 10) {
      setLoading(true);
      const response = await fetchApi(
        `guest/auth/onlineOrdering/sendOtp/${noMaskPhoneNumber}/${metadataState.location._id}/${metadataState.location.name}`,
        {},
        "post"
      );
      if (response?.status === 200) {
        setMode(1);
      }
      setLoading(false);
    } else {
      ToastService.showFailure("Please enter a valid Phone Number");
    }
  };

  const verifyOtp = async () => {
    let noMaskPhoneNumber = phoneNumber.replace(/[\(\)\s-]/g, "");

    if (noMaskPhoneNumber?.length !== 10) {
      ToastService.showFailure("Please enter a valid Phone Number");
      return;
    }
    if (otp?.length !== 5) {
      ToastService.showFailure("Please enter a valid Otp");
      return;
    }

    setLoading(true);
    const response = await fetchApi(
      `guest/auth/onlineOrdering/verifyOtp/${noMaskPhoneNumber}/${metadataState.location._id}/${otp}`,
      {},
      "post"
    );
    if (response?.status === 200) {
      if (response.data) {
        LocalStorageService.saveToken(response.data.token);
        ToastService.showSuccess(`Welcome back ${response.data.name}`);
        // DataDogService.setUserData();
        onClose();
      } else {
        setMode(2);
      }
    }
    setLoading(false);
  };

  const register = async () => {
    let noMaskPhoneNumber = phoneNumber.replace(/[\(\)\s-]/g, "");

    if (noMaskPhoneNumber?.length !== 10) {
      ToastService.showFailure("Please enter a valid Phone Number");
      return;
    }

    if (!firstName || !lastName || !emailId) {
      ToastService.showFailure("Please fill all the mandatory fields");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailId)) {
      ToastService.showFailure("Please enter a valid Email Id");
      return;
    }

    setLoading(true);
    const response = await fetchApi(
      "guest/auth/onlineOrdering/register",
      {
        locationId: metadataState.location._id,
        firstName,
        middleName,
        lastName,
        emailId,
        contactNumber: noMaskPhoneNumber,
      },
      "post"
    );
    if (response?.status === 200) {
      if (response.data) {
        LocalStorageService.saveToken(response.data.token);
        ToastService.showSuccess(`Welcome back ${response.data.name}`);
        // DataDogService.setUserData();
        onClose();
      } else {
        setMode(2);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      visible={true}
      className="kuber-dialog"
      onHide={() => onClose()}
      draggable={false}
      style={{ width: "30vw" }}
      header={
        mode === 2 ? "Registration" : `Login to ${metadataState.location.name}`
      }
    >
      <div className="m-0">
        {mode === 0 || mode === 1 ? (
          <div className="p-inputgroup flex-1 mt-3">
            <span className="p-inputgroup-addon">+1</span>
            <InputMask
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value ?? "")}
              placeholder="Phone Number"
              style={{ height: 50 }}
              mask="(999) 999-9999"
              disabled={mode != 0}
              keyfilter="int"
            />
          </div>
        ) : (
          <></>
        )}
        <p style={{ fontSize: "10px", marginTop: "2px" }}>
          By agreeing to receive text messages, you acknowledge that standard
          text messaging rates from your wireless carrier may apply.
        </p>

        {mode === 1 ? (
          <OTPInput
            inputStyle={"form-control"}
            value={otp}
            onChange={setOtp}
            numInputs={5}
            containerStyle={"mt-3 otp"}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
            inputType="number"
          />
        ) : (
          <></>
        )}

        {mode === 2 ? (
          <>
            <InputText
              className="mt-3"
              style={{ height: "50px" }}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name*"
            />
            <InputText
              className="mt-3"
              style={{ height: "50px" }}
              onChange={(e) => setMiddleName(e.target.value)}
              placeholder="Middle Name"
            />
            <InputText
              className="mt-3"
              style={{ height: "50px" }}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name*"
            />
            <InputText
              className="mt-3"
              style={{ height: "50px" }}
              onChange={(e) => setEmailId(e.target.value)}
              placeholder="Email*"
            />
          </>
        ) : (
          <></>
        )}

        {mode === 0 ? (
          <div className="d-flex justify-content-center">
            <Button
              className="mt-4 btn btn-salmon-background"
              style={{ height: 48, width: "100%" }}
              label="Proceed"
              loading={loading}
              onClick={() => sendOtp()}
            />
          </div>
        ) : mode === 1 ? (
          <div className="d-flex justify-content-center">
            <Button
              className="mt-4 btn btn-salmon-background"
              style={{ height: 48, width: "100%" }}
              label="Verify Otp"
              loading={loading}
              onClick={() => verifyOtp()}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Button
              className="mt-4 btn btn-salmon-background"
              style={{ height: 48, width: "100%" }}
              label="Register"
              loading={loading}
              onClick={() => register()}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}
