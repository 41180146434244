function formatUSPhoneNumber(number) {
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

function formatNumberToTwoDigitDecimal(value) {
  return parseFloat(value).toFixed(2);
}

export { formatUSPhoneNumber, formatNumberToTwoDigitDecimal };
