'use client';

import { useContext, useEffect, useState } from "react";
import PageHead from "./PageHead";
import PageHero from "./PageHero";
import { MetadataContext } from "../../context/metadata.context";
import Footer from "./Footer";
import Header1 from "./Header1";
import Header2 from "./Header2";
import Loader from "../loader";

export default function Layout({ headerStyle, children, pageTitle, pageId, activePage, headerCls, headTitle }: any) {
    const [scroll, setScroll] = useState(false);
    const { state } = useContext(MetadataContext);
    
    
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100;
            setScroll(scrollCheck);
        })
    })

    const [isToggled, setToggled] = useState(false);
    const handleToggle = () => setToggled(!isToggled);

    return (
        <>
            {state.isLoading ? <Loader /> : <></>}
            <PageHead headTitle={headTitle} />
            {!headerStyle && <Header1 headerCls={headerCls} scroll={scroll} isToggled={isToggled} handleToggle={handleToggle} />}
            {headerStyle == 1 && <Header1 headerCls={headerCls} scroll={scroll} isToggled={isToggled} handleToggle={handleToggle} />}
            {headerStyle == 2 && <Header2 headerCls={headerCls} scroll={scroll} isToggled={isToggled} handleToggle={handleToggle} />}

            <div id="page" className="page">
                {pageTitle && <PageHero pageId={pageId} pageTitle={pageTitle} activePage={activePage} />}
                {children}

                <Footer />
            </div>
        </>
    )
}
