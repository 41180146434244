import moment from "moment";

const today = moment().format('MM/DD/YYYY');
const toLocalDate = (dateString, format = "DD-MMM-YYYY") => {
  if (!dateString) return "";
  return moment(dateString).format(format);
};

const getDateTime = (time, t) => {
  return time ? moment(new Date(`${today} ${time}`))?.toISOString() : "--";
};

const change24HrsFormatTo12Hrs = (timeString: string) => {
  const time = moment(timeString, 'HH:mm');
  return time.format('h:mm A');
};

const utc = () => {
  return moment.utc();
}

export { toLocalDate, getDateTime, change24HrsFormatTo12Hrs, utc };
