import Link from "next/link";
import { Dispatch, useContext } from "react";
import {
  MetadataContext,
  MetadataStateModel,
} from "../../context/metadata.context";
import { formatUSPhoneNumber } from "@/utils/formatter";
import { ActionType } from "@/context/order.context";
import { change24HrsFormatTo12Hrs } from "@/utils/dates";

export default function Footer() {
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);

  return (
    <>
      <footer id="footer-4" className="footer division">
        <div className="container grey-color">
          <div className="row">
            <div className="col-xl-3">
              <div className="footer-info mb-40">
                <div className="footer-logo">
                  <img src={metadataState.location?.logo} alt="Logo" />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-4">
              <div className="footer-contacts mb-40">
                <h5 className="h5-sm white-color">Address</h5>

                <p>{metadataState.location?.address}</p>

                <p className="foo-email txt-500 mt-15">
                  <a href={`mailto:${metadataState.location?.email}`}>
                    {metadataState.location?.email}
                  </a>
                </p>
                <p>
                  <span className="yellow-color">
                    <a
                      href={`tel:${metadataState.location?.contactNumber}`}
                    >
                      +1{" "}
                      {formatUSPhoneNumber(
                        metadataState.location?.contactNumber
                      )}
                    </a>
                  </span>
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 col-xl-5">
              <div className="footer-info mb-30">
                <>
                  <h5 className="h5-sm white-color">Working Hours</h5>

                  {metadataState.locationTimetable.timetable.map((timetable, index) => {
                    return (
                      <p className="mt-15" key={index}>
                        <span
                          style={{
                            color: !timetable?.status ? "red" : "#f7be27",
                            fontWeight: "bold",
                          }}
                        >
                          {timetable?.weekDay.substring(0, 3)}:
                        </span>
                        {timetable?.slots.map((slot, timeSlotIndex) => {
                          return (
                            <span style={{ fontSize: 16 }} key={timeSlotIndex}>
                              {change24HrsFormatTo12Hrs(slot.startTime)} -{" "}
                              {change24HrsFormatTo12Hrs(slot.endTime)}{" "}
                              {timeSlotIndex === timetable.slots?.length - 1
                                ? null
                                : ","}
                            </span>
                          );
                        })}
                      </p>
                    );
                  })}
                </>
              </div>
            </div>
          </div>

          <div className="bottom-footer">
            <div className="row d-flex align-items-center">
              <div className="col-md-5 col-lg-6">
                <div className="footer-copyright">
                  <p>
                    © {new Date().getFullYear()} Kuber POS. All Rights Reserved
                  </p>
                </div>
              </div>

              {/* <div className="col-md-7 col-lg-6">
                                <ul className="bottom-footer-list text-right clearfix">
                                    <li><p className="first-list-link"><Link href="#"><i className="fab fa-facebook-f" /> Facebook</Link></p></li>
                                    <li><p><Link href="#"><i className="fab fa-twitter" /> Twitter</Link></p></li>
                                    <li><p><Link href="#"><i className="fab fa-youtube" /> YouTube</Link></p></li>
                                    <li><p className="last-li"><Link href="#"><i className="fab fa-yelp" /> Yelp</Link></p></li>
                                </ul>
                            </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
